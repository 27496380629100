import React, { useState } from 'react'
import cn from 'classnames'
import { groupBy } from 'lodash-es'
import { Link, graphql, PageProps } from 'gatsby'

import { SanityProject } from '../../types'
import { Layout } from "../../templates"
import { SEO } from "../../partials"
import { Container, Columns, Column, Heading, Icon, Toggler } from '../../components'
import { marginClass } from '../../library/spacing'

type ProjectArchiveProps = PageProps<{
  allSanityProject: {
    nodes: SanityProject[]
  }
}>

const ProjectArchive: React.FC<ProjectArchiveProps> = (props) => {
  const [filter, setFilter] = useState<number>(0)
  const filteredProjects = props.data.allSanityProject.nodes.filter((proj) => {
    if (filter === 1) return true;

    return proj.isFeatured;
  })
  const groupedProjects = groupBy(filteredProjects, 'finishDate')


  return (
    <Layout>
      <SEO title="Projects" />
      <div className="spacer" style={{ minHeight: 80, height: '40vh' }}></div>
      <Container maxWidth="lg">
        <Columns className={cn(marginClass([null, null, 8]))}>
          <Column>
            <Heading size={3} className="c--jazzy">
              <Toggler options={['Featured', 'All']} onUpdate={setFilter}>Featured</Toggler> Projects —
            </Heading>
          </Column>
        </Columns>

        {Object.keys(groupedProjects)
          .sort((a, b) => (parseInt(b) - parseInt(a)))
          .map((year) => (
            <Columns key={year} className={cn(marginClass([8, null]))}>
              <Column>
                <Heading family="mono" size={2} className="c--gray-light">{year}</Heading>
                <ul className="project-list">
                  {groupedProjects[year].map((project) => (
                    <li className={cn('project-list__item')} key={project.id}>
                      <Link
                        to={`/projects/${project.slug.current}`}
                        className={cn('project-list__link', 'heading', 'f--6')}
                      >
                        {project.title}
                      </Link>

                      {project.indexPhoto &&
                        <div className="project-list__image">
                          <img
                            src={project.indexPhoto.asset.url}
                            className={'-fill'} alt=""
                          />
                        </div>
                      }
                    </li>
                  ))
                  }
                </ul>
              </Column>
            </Columns>
          ))}
      </Container>
      <div className="spacer" style={{ minHeight: 80, height: '40vh' }}></div>
    </Layout>
  )
}

export default ProjectArchive

export const archiveQuery = graphql`
  {
    allSanityProject(sort: {fields: title}) {
      nodes {
        title
        isFeatured
        slug {
          current
        }
        indexPhoto {
          asset {
            url
          }
        }
        finishDate(formatString: "YYYY")
        tags
      }
    }
  }
`
